<template>
  <div :class="$style.root">
    <VForm @submit.prevent="onSubmit">
      <VAlert v-if="!Object.keys(fields).length" prominent text type="error" dense class="mb-6">
        По договору нет выгруженного времени
      </VAlert>
      <template v-else>
        <VRow>
          <VCol cols="12" lg="6">
            <VRow>
              <VCol v-for="(field) in fields" cols="12" md="6" :key="field.month">
                <template v-if="readonly || field.disabled">
                  <StaticField :value="field.value" :label="field.month" />
                </template>
                <template v-else>
                  <VTextField v-model="field.value" :label="field.month" dense outlined hide-details="auto" />
                </template>
              </VCol>
              <VCol v-if="!readonly" cols="12">
                <VBtn color="primary" dense depressed :disabled="!diff" @click="onSubmit">Сохранить</VBtn>
                <VBtn v-if="diff" color="secondary" depressed class="ml-3" @click="onReset">Отмена</VBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </template>
    </VForm>
  </div>
</template>

<script>
import StaticField from '@/components/general/StaticField/StaticField';
export default {
  name: 'WorkHoursForm',
  components: {
    StaticField,
  },
  props: {
    readonly: { type: Boolean },
    values: { type: Object, default: () => ({}) }
  },
  data: function() {
    return {
      fields: JSON.parse(JSON.stringify(this.values)),
    }
  },
  computed: {
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
  },
  methods: {
    onSubmit: function() {
      this.$emit('submit', this.fields);
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
  },
  watch: {
    values: {
      handler: function() {
        this.onReset();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  .btn {
    height: 100% !important;
  }
}
</style>
